.Contacts {
    background: #F0F0F0;
    margin-bottom: 0;

    .map {
        width: 100%!important;
        height: 100%!important;
    }

    .content {
        padding: 5rem 0 4rem;

        .secondary {
            color: #615F64;
            font-size: .9rem;
            margin-top: 1rem;
            padding-right: 3rem;
        }

        .buttonLink {
            font-size: 1rem;
        }

        .list {
            list-style: none;
            margin-top: 2rem;
            padding: 0;

            > li {
                font-size: 1.2rem;
                font-weight: 600;
                position: relative;
                padding-left: 3rem;
                margin: 1rem 0;

                img {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                a {
                    color: #252525;
                }
            }
        }
    }

    @media (max-width: 1600px) {
        .content {
            .list {
                > li {
                    font-size: 1rem;
                    padding-left: 2.5rem;

                    img {
                        width: 30px;
                    }
                }
            }

            .buttonLink {
                font-size: .9rem;
            }
        }
    }
    @media (max-width: 992px) {
        .row {
            flex-flow: column-reverse;
        }

        .map {
            height: 600px!important;
        }
    }
    @media (max-width: 768px) {
        .map {
            height: 400px!important;
        }

        .content {
            padding: 4rem 0 3rem;
        }
    }
    @media (max-width: 568px) {
        .map {
            height: 300px!important;
        }
    }
}