.Services {
    margin-top: -3.75rem;

    .title {
        border-bottom: 1px solid #CECECE;
        padding-bottom: 2rem;
    }

    .list {
        margin-top: 1.5rem;
    }

    .card {
        border: none;
        margin-bottom: 1rem;

        .img {
            border-radius: .5rem;
        }

        .body {
            padding-left: 0;
            padding-right: 0;
            padding-top: 1rem;
            line-height: 1.25;

            p {
                margin-bottom: .75rem;
            }
        }
    }

    @media (max-width: 1600px) {
        .card {
            .body {
                font-size: .9rem;
            }
        }
    }
    @media (max-width: 992px) {
        margin-top: -2.5rem;
    }
    @media (max-width: 568px) {
        margin-top: 1rem;

        .title {
            padding-bottom: 1.5rem;
        }
    }
}