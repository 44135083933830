.Footer {
    background: #252525;
    padding: 4.5rem 0 3rem;
    color: #fff;

    .logo {
        height: 54px;
    }

    .copyright {
        color: #CECECE;
        font-size: 16px;
        margin-bottom: .75rem;
    }

    .subtitle {
        font-size: 1rem;
        color: #CECECE;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }

    .servicesList {
        margin-bottom: 0;
        font-size: 16px;
        padding: 0;
        list-style: none;

        li {
            position: relative;
            padding-left: 1rem;
            margin: .75rem 0;

            &:before {
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #512D84;
                position: absolute;
                top: .35rem;
                left: 0;
            }
        }
    }

    .contactsList {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
            font-weight: 600;
            position: relative;
            padding-left: 3rem;
            margin: 1.5rem 0;

            img {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            a {
                color: #fff;
            }
        }
    }

    @media (max-width: 1600px) {
        padding-bottom: 2rem;
        
        .servicesList {
            > li {
                font-size: .9rem;
            }
        }

        .contactsList {
            > li {
                font-size: .9rem;
                padding-left: 2.25rem;

                img {
                    width: 30px;
                }
            }
        }
    }
    @media (max-width: 992px) {
        .servicesList {
            margin-bottom: 3rem;
        }

        .copyright {
            margin-bottom: 1.25rem;
        }
    }
    @media (max-width: 768px) {
        padding-top: 3rem;
        padding-bottom: 1rem;

        .logo {
            height: 34px;
            max-width: 120px;
            margin-bottom: 2rem;
        }
    }
}