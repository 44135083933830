.headerWrapper {
    border-bottom: 1px solid #CECECE;
}

header {
    padding: 0.1rem 0;

    .logo img {
        transition: .2s;
        height: 52px;
    }

    .nav {
        > li > a {
            font-weight: 600;
            font-size: .9rem;

            &:hover {
                color: #512D84;
            }
        }
    }

    .info {
        line-height: 1;

        .primary {
            color: #512D84;
            font-weight: 600;
            font-size: 1.2rem;
            line-height: 1;
        }

        .secondary {
            font-weight: 500;
            font-size: 15px;
            color: #252525;
        }
    }

    @media (max-width: 992px) {
        .nav {
            padding: .75rem 0;

            > li {
                margin: .75rem 0;
                > a {
                    font-size: 1.25rem;
                }
            }
        }

        .info {
            margin-right: 30px;
        }
    }
    @media (max-width: 768px) {
        .logo img {
            height: 34px;
            max-width: 120px;
        }
    }
    @media (max-width: 568px) {
        .info {
            margin-right: 1.25rem;
            display: flex;

            > div:not(:first-child) {
                margin-left: 1.25rem;
                
            }
        }

        .nav {
            padding: .5rem 0;

            > li {
                margin: .5rem 0;
                > a {
                    font-size: 1rem;
                }
            }
        }
    }
}