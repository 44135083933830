.About {
    margin: 7rem 0;

    .title {
        margin-bottom: 2rem;
    }

    .content {
        margin-bottom: 3rem;

        p {
            font-size: .9rem;
            color: #615F64;
            line-height: 1.4;
            text-align: justify;
        }
    }

    @media (max-width: 992px) {
        .row {
            flex-flow: column-reverse;
        }

        .picturePrimary {
            width: 400px;
            margin: auto;
            display: block;
        }
    }
    @media (max-width: 768px) {
        margin: 5rem 0;
    }
    @media (max-width: 568px) {
        margin: 4rem 0;
    }
}