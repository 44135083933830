.Features {
    background-color: #F0F0F0;
    margin-top: 5rem;
    position: relative;
    overflow: hidden;


    .picture {
        position: relative;

        &:before {
            content: '';
            background-image: url('../../media/features_logo.png');
            background-repeat: no-repeat;
            background-position: right;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;
            width: calc(100% - 30px);
            height: 100%;
        }
    }

    .content {
        padding: 6rem 0 4rem;
    }

    .card {
        margin: 2.25rem 0;

        .header {
            border-bottom: 1px solid #CECECE;
            padding-bottom: 1rem;
            margin-bottom: .75rem;
        }
    }

    @media (max-width: 1600px) {
        .card {
            font-size: .9rem;
        }
    }
    @media (max-width: 992px) {
        .picture {
            &:before {
                width: calc(100% + 30px);
            }
        }

        .content {
            padding: 4rem 0 4rem;
        }
    }
    @media (max-width: 768px) {
        margin-top: 3rem;
        
        .content {
            padding: 3rem 0 3rem;
        }
    }
    @media (max-width: 568px) {
        .picture {
            &:before {
                display: none;
            }
        }
    }
}