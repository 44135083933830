html {
	scroll-behavior: smooth;
	font-size: 16px;
	height: 100%;

	@media (min-width: 1280px) {
		font-size: 18px;
	}
    @media (min-width: 1600px) {
		font-size: 20px;
	}
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    -webkit-overflow-scrolling:touch;
    font-family: 'Inter', sans-serif;
    color: #252525;
    font-weight: 500;
    line-height: 1.3;
}

$primary: #512D84;
$primary-hover: #6F36C0;

@media (min-width: 1200px) {
    .container {
        max-width: 1040px;
    }
}

@media (min-width: 1480px) {
    .container {
      max-width: 1310px;
    }
}
  
@media (min-width: 1680px) {
    // .container {
    // max-width: 1630px;
    // }
    .col-xxl-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }
    .col-xxl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
    .col-xxl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xxl-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-xxl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }
    .col-xxl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xxl-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%
    }
    .col-xxl-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }
    .col-xxl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xxl-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }
    .col-xxl-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }
    .col-xxl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .col-xxl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }
    
    .offset-xxl-1 {
        margin-left: 8.333333%;
    }
    .offset-xxl-2 {
        margin-left: 16.666667%;
    }
    .offset-xxl-3 {
        margin-left: 25%;
    }
}

.sticky-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;

    .navbar-brand img {
        height: 34px;
    }
}

section {
    margin-top: 3rem;
    margin-bottom: 3rem;

    .section-title {
        font-weight: 600;
        font-size: 3rem;
        line-height: 1;
    } 

    @media (max-width: 1600px) {
        .section-title {
            font-size: 2.5rem;
        }
    }
    @media (max-width: 768px) {
        .section-title {
            font-size: 2rem;
        }
    }
    @media (max-width: 568px) {
        .section-title {
            font-size: 1.75rem;
        }
    }
    @media (max-width: 414px) {
        .section-title {
            font-size: 1.5rem;
        }
    }
}

img {
    max-width: 100%;
}

a {
    color: $primary;

    &:hover,
    &:active {
        color: $primary-hover;
    }
}

.col-auto {
    max-width: 100%;
}

.navbar-light .navbar-nav .nav-link {
    color: #252525;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @media (max-width: 992px) {
        padding-left: 1.75rem;
        padding-right: 1.75rem;
    }
}

.btn {
    font-size: 16px;
    font-weight: 700;
    padding: 0.75rem 1.75rem;
    
}
.btn-primary {
    border-color: $primary;
    background-color: $primary;
    box-shadow: 0px 12px 24px 0px rgba(81, 45, 132, .3);

    &:not(:disabled):not(.disabled).active, 
    &:not(:disabled):not(.disabled):active, 
    &:not(:disabled):not(.disabled).active:focus, 
    &:not(:disabled):not(.disabled):active:focus,
    &:focus,
    &:hover,
    &:active {
        background-color: $primary-hover;
        border-color: $primary-hover;
        box-shadow: none;
    }
}
.btn-link {
    padding: 0;
    color: $primary;

    &:not(:disabled):not(.disabled).active:focus, 
    &:not(:disabled):not(.disabled):active:focus,
    &:focus,
    &:hover,
    &:active {
        color: $primary-hover;
        text-decoration: none;

        i {
            background-color: $primary-hover;
        }
    }

    i {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $primary;
        display: inline-block;
        line-height: 1;
        vertical-align: middle;
        position: relative;
        margin-left: 6px;
        margin-bottom: 2px;
        background-image: url('../src/media/arrow-right.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
}
.btn-outline-secondary {
    color: #252525;
    border-color: #CECECE;

    &:not(:disabled):not(.disabled).active:focus, 
    &:not(:disabled):not(.disabled):active:focus,
    &:focus,
    &:hover,
    &:active {
        background-color: #CECECE;
        border-color: #CECECE;
        color: #252525;
        box-shadow: none;
    }
}
button:focus {
    outline: none;
}
.primary-text {
    color: $primary-hover;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 630px;
    }
}
.modal-header,
.modal-body {
    padding: 1.5rem;
}
.modal-footer,
.modal-body {
    padding: 1.5rem 1.5rem 2rem 1.5rem;
}
.modal-content {
    border-radius: 10px;
}
.modal-title {
    strong {
        font-weight: 700;
        font-size: 40px;
        margin-bottom: .25rem;
    }
    
    p {
        font-size: .9rem;
        color: #615F64;
        margin-bottom: 0;
    }

    @media (max-width: 768px) {
        strong {
            font-size: 30px;
        }
    }
    @media (max-width: 568px) {
        strong {
            font-size: 22px;
        }
    }
}
.modal-header {
    border-bottom: none;
    padding-bottom: 0;
}
.form-control {
    font-size: 16px;
    color: #252525;
    border-radius: 3px;
    border: 1px solid #CECECE;

    &:focus {
        border-color: #CECECE;
        box-shadow: none;
    }

    &[aria-invalid=true] {
        border-color: #ff0000;
    }
}
.form-check {
    padding-left: 0;
    margin: 1.25rem 0;

    .form-check-label {
        font-size: 14px;
        vertical-align: top;
        line-height: 20px;
        user-select: none;
        display: flex;
        
        span {
            padding-left: 10px;
            line-height: 20px;
            display: inline-block;
            vertical-align: top;
        }
    }

    .form-check-input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        & + i {
            border-radius: 3px;
            border: 1px solid var(--Divider, #CECECE);
            background: var(--White, #FFF);
            display: inline-block;
            width: 20px;
            min-width: 20px;
            height: 20px;
            cursor: pointer;

            &:after {
                content: '';
                width: 20px;
                height: 20px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 22;
                background-color: $primary;
                background-image: url('../src/media/check.svg');
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 3px;
                transition: .2s;
                transform: scale(0);
            }
        }

        &:checked + i:after {
            transform: scale(1);
        }
        &[aria-invalid=true] + i {
            border-color: #ff0000;
        }
    }
}
.field-error {
    font-size: 13px;
    color: #ff0000;
}
.navbar-toggler {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-color: #CECECE;
    position: relative;

    .navbar-toggler-icon {
        display: none;
    }

    &:before,
    &:after {
        content: '';
        display: block;
        width: 20px;
        height: 2px;
        margin: auto;
        background-color: $primary;
        position: absolute;
        left: 0;
        right: 0;
        transition: .2s;
    }

    &:before {
        top: 18px;
    }
    &:after {
        top: 28px;
    }

    span {
        display: block;
        width: 20px;
        height: 2px;
        margin: auto;
        background-color: $primary;
    }

    &[aria-expanded=true] {
        span {
            display: none;
        }

        &:before {
            transform: translateY(5px) rotate(45deg);
        }
        &:after {
            transform: translateY(-5px) rotate(-45deg);
        }
    }
}
@media (max-width: 992px) {
    .navbar-collapse {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 77;
        background: #fff;
        box-shadow: 0px 12px 24px 0px #0000001A;
        border: 1px solid #CECECE;
        border-radius: 10px;
    }
}