.Intro {
    padding-bottom: 10rem;
    position: relative;
    background-image: url('../../media/intro_bg.png');
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0;

    .content {
        padding-right: 2rem;
        font-weight: 500;

        h1 {
            font-weight: 600;
            line-height: 1;
            font-size: 3.5rem;
            margin-bottom: 2rem;
        }

        p {
            line-height: 1.2;
            margin-bottom: 2rem;
        }
    }

    @media (max-width: 1600px) {
        padding-bottom: 8rem;
        
        .content {
            h1 {
                font-size: 3rem;
            }
        }
    }
    @media (max-width: 992px) {
        padding-top: 4rem;

        .content {
            padding-right: 0;
            padding-bottom: 4rem;
        }
    }
    @media (max-width: 768px) {
        .content {
            h1 {
                font-size: 2.5rem;
            }
        }
    }
    @media (max-width: 568px) {
        padding-top: 3rem;
        padding-bottom: 4rem;

        .content {
            h1 {
                font-size: 2.25rem;
            }

            button {
                width: 100%;
            }
        }
    }
}